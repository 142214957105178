import React from 'react';
import { Link, useLocation } from 'react-router-dom';

type NavItemProps = {
  text: string;
  to: string;
  onClick?: () => void;
};

export const NavItem = (props: NavItemProps) => {
  const { text, to, onClick } = props;
  const location = useLocation();

  const isActive = location.pathname === to;

  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <div className="nav-item">
      <Link to={to} className={isActive ? 'active' : ''} onClick={handleClick}>
        {text}
      </Link>
    </div>
  );
};
