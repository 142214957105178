import React, { useEffect, useState } from 'react';
import scan from '../../../assets/images/scan.png';
import circles from '../../../assets/images/cerchi.svg';
import { useDispatch } from 'react-redux';
import { clearAuthentication, setAddress } from '../../../reducers/auth.reducer';
import { handleLogin, isLoggedIn } from '../../../scripts/NFT_building_handler.mjs';
import { toast } from 'react-toastify';
import { application_error_msgs } from '../../../config/constants';
import { handleXUMMSignFlow, isXumm } from '../../../scripts/xumm_handler.mjs';
import { isMobileUser } from '../../../scripts/utils/utils.mjs';
import { Modal } from '../../../components/shared/modal/modal';
import { WrappedEventTarget } from '../../../scripts/classes/WrappedEventTarget.mjs';

type TState = {
  loading: boolean;
  qrVisible: boolean;
  qrUrl: string;
  signUrl: string;
  open: boolean;
  errorModal: boolean;
};

export const ConnectWallet = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState<TState>({
    loading: false,
    qrVisible: false,
    qrUrl: '',
    signUrl: '',
    open: false,
    errorModal: false
  });

  const openErrorModal = () => {
    setState({
      ...state,
      errorModal: true
    });
  };
  const closeErrorModal = () => {
    setState({
      ...state,
      errorModal: false
    });
  };

  const sign_in_event_target = new WrappedEventTarget();

  sign_in_event_target.addEventHandler('xumm_payload_created', (data: any) => {
    onPayloadCreated(data);
  });

  sign_in_event_target.addEventHandler('xumm_payload_expired', () => {
    dispatch(clearAuthentication());
  });

  sign_in_event_target.addEventHandler('logged_in', (data: any) => {
    setState({
      ...state,
      loading: false,
      qrVisible: false,
      qrUrl: ''
    });
    dispatch(setAddress(data.address));
    sign_in_event_target.clearEventHandlers();
  });

  const onPayloadCreated = (data: any) => {
    if (isXumm()) {
      handleXUMMSignFlow(data.payload_created.uuid);
    } else if (!isMobileUser()) {
      setState({
        ...state,
        loading: false,
        qrVisible: true,
        qrUrl: data.payload_created.refs.qr_png
      });
    } else {
      setState({
        ...state,
        loading: false,
        signUrl: data.payload_created.next.always,
        open: true
      });
    }
  };

  const onPayloadSignedOrError = (data: any) => {
    console.log('Payload said -->', data);
    setState({
      ...state,
      open: false,
      loading: false,
      qrVisible: false,
      qrUrl: ''
    });
  };

  const doLogin = async () => {
    try {      
      const res = await handleLogin(sign_in_event_target);
      onLoginSucces(res.data);
    } catch (e: any) {
      console.log(e);
      if (application_error_msgs.includes(e['message'])) {
        toast(e, { type: 'error' });
      } else {
        checkUserIsLoggedIn();
      }
    }
  };

  const checkUserIsLoggedIn = async () => {
    try {
      const res = await isLoggedIn();
      onLoginSucces(res.data);
    } catch (e: any) {
      toast(e, { type: 'error' });
    }
  };

  const onLoginSucces = (data: any) => {
    if (data?.is_logged_in && data?.address) {
      sign_in_event_target.dispatchEvent('logged_in', {
        address: data.address
      });
    } else {
      if (!data.is_network_ok) {
        openErrorModal();
      } else {
        onPayloadSignedOrError(null);
      }
    }
  };

  return (
    <>
      <div className="left-col">
        <div className="hero">
          <h1>Let’s start!</h1>

          <p>Click the button below to connect your XRPL wallet.</p>

          <button
            className={`button md dark rounded bold ${state.qrVisible ? 'gradient' : 'dark'} `}
            onClick={doLogin}
          >
            {state.loading
              ? 'loading'
              : state.qrVisible
              ? '>>>>>>>>>>>>>>>>'
              : 'Connect your Wallet'}
          </button>
          {/*
          <p className="hero-small-text">
            Be aware that this is a test tool working on XRPL NFT-Devnet.
          </p>
          */}
        </div>
      </div>
      <div className="right-col sm-hidden scan">
        <div className="image-wrapper homepage">
          <img src={circles} />
        </div>
        <div className="image-wrapper scan">
          {state.qrVisible && (
            <>
              <img src={scan} className="img-content" />
              <div className="qr">
                <span>scan me</span>
                <img src={state?.qrUrl} />
              </div>
            </>
          )}
        </div>
      </div>

      {state.open && (
        <Modal size="xs">
          <div className="qr-modal">
            <p>Sign transaction on XUMM app</p>
            <a
              className="button sm solid dark rounded"
              href={state.signUrl}
              target="_blank"
              rel="noreferrer noopener"
            >
              Sign
            </a>
          </div>
        </Modal>
      )}

      {state.errorModal && (
        <Modal size="xs" close={closeErrorModal} hideHeader={false}>
          <>
            Please connect to the NFT DEVNET to proceed, &nbsp;
            <a
              href="https://www.youtube.com/watch?v=etHVD9-F0NA&ab_channel=Aesthetes"
              target="_blank"
              rel="noreferrer noopener"
              style={{ color: 'black' }}
            >
              check this video
            </a>
            &nbsp; for further informations.
          </>
        </Modal>
      )}
    </>
  );
};
