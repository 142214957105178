import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../reducers';
import { ConnectWallet } from './connect-wallet/connect-wallet';
import { Steps } from './steps/steps';

export const Create = () => {
  const auth = useSelector((state: IRootState) => state.authentication);

  return (
    <div className="page-template">{!auth.isAuthenticated ? <ConnectWallet /> : <Steps />}</div>
  );
};
