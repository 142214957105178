import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeStep, setStepData } from '../../../../reducers/form.reducer';
import { Advanced } from './advanced';
import { SelectFile } from './select-file';
import { UploadFile } from './upload-file';

export type TStep1Values = {
  cid: string | null;
  file: File | null;
  advanced: boolean;
};

export const Step1 = () => {
  const dispatch = useDispatch();

  const [stepState, setStepState] = useState<TStep1Values>({
    cid: null,
    file: null,
    advanced: false
  });

  const toggleAdvanced = () => {
    setStepState({
      cid: null,
      file: null,
      advanced: !stepState.advanced
    });
  };

  const onSetCid = (cid: string) => {
    setStepState({
      ...stepState,
      cid
    });
  };

  const onSetFile = (file: File | null) => {
    setStepState({
      ...stepState,
      file
    });
  };

  const onNext = () => {
    dispatch(changeStep(2));
    dispatch(setStepData('step1', stepState));
  };

  return (
    <>
      <div className="left-col">
        <div className="hero">
          <h1 className="step-title">Step one</h1>
        </div>
      </div>
      <div className="right-col form">
        <div className="step-form">
          <h2 className="step-subtitle">
            {' '}
            {stepState.advanced ? 'Insert the IPFS CID' : 'Select your NFT'}
          </h2>
          <div className="step-content">
            {stepState.advanced ? (
              <Advanced onSetCid={onSetCid} />
            ) : (
              <SelectFile onSelect={onSetFile} />
            )}
          </div>

          <div className="step-actions">
            <button
              className={`button bold advanced-options ${stepState.advanced ? 'on gradient' : ''}`}
              onClick={toggleAdvanced}
            >
              <div className="circle" />
              <div className="text">Advanced options</div>
            </button>
            <button
              className="button md dark rounded bold "
              disabled={
                (stepState.advanced && !stepState.cid) || (!stepState.advanced && !stepState.file)
              }
              onClick={onNext}
            >
              OK, go ahead
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
