import axios from 'axios';
import { FAILURE, REQUEST, SUCCESS } from './action-type.util';

export const ACTION_TYPES = {
  MINT: 'mint/MINT',
  MINT_DONE: 'faucet/MINT_DONE',
  TOGGLE_MINT: 'faucet/TOGGLE_MINT'
};

const initialState = {
  paying: false,
  minting: false,
  success: false,
  error: false,
  done: false,
  open: false
};

export type MintState = Readonly<typeof initialState>;

// Reducer

export default (state: MintState = initialState, action: any): MintState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.MINT):
      return {
        ...state,
        paying: true
      };
    case FAILURE(ACTION_TYPES.MINT):
      return {
        ...initialState,
        paying: false,
        error: true
      };

    case SUCCESS(ACTION_TYPES.MINT):
      return {
        ...state,
        paying: false,
        success: true
      };

    case ACTION_TYPES.MINT_DONE:
      return {
        ...state,
        done: true
      };

    case ACTION_TYPES.TOGGLE_MINT:
      return {
        ...state,
        open: !state.open
      };

    default:
      return state;
  }
};

export const mint: () => void = () => async (dispatch: any) => {
  await dispatch({
    type: ACTION_TYPES.MINT,
    payload: axios.post(`/endpoints/faucet/ELSFaucet`)
  });
};

export const mintDone = () => (dispatch: any) => {
  dispatch({
    type: ACTION_TYPES.MINT_DONE
  });
};

export const toggleMint = () => (dispatch: any) => {
  dispatch({
    type: ACTION_TYPES.TOGGLE_MINT
  });
};
