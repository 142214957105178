import { isUndefinedOrNull } from './utils.mjs';
import axios from 'axios';

var endpoints_URL = null;

export const setEndpointsURL = function (_endpoints_URL) {
  endpoints_URL = _endpoints_URL;
  console.log("callRestApi(): endpoints_URL: ", endpoints_URL);
};

export const callRestApi = async function (method, endpoint_path, data = {}, config = {}) {
  const function_endpoint_url = endpoints_URL + endpoint_path;

  console.log("callRestApi(): ", function_endpoint_url);

  if (method === 'GET') {
    return axios.get(function_endpoint_url, config);
  } else if (method === 'POST') {
    return axios.post(function_endpoint_url, data, config);
  } else if (method === 'PUT') {
    return axios.put(function_endpoint_url, data, config);
  } else if (method === 'DELETE') {
    return axios.delete(function_endpoint_url, config);
  } else {
    return null;
  }
};

export const extractDataFromAxiosResponse = function (_response) {
  if (isUndefinedOrNull(_response)) {
    throw new Error('extractDataFromAxiosResponse(): _response is undefined or null');
  }

  const _response_status = _response['status'];
  if (isUndefinedOrNull(_response_status)) {
    throw new Error('extractDataFromAxiosResponse(): _response_status is undefined or null');
  }
  const _response_data = _response['data'];
  if (isUndefinedOrNull(_response_data)) {
    if (_response_status !== 200) {
      throw new Error('extractDataFromAxiosResponse(): _response_status is not 200');
    } else {
      throw new Error('extractDataFromAxiosResponse(): _response_data is undefined or null');
    }
  }

  if (_response_status !== 200) {
    const _response_data_error_message = _response_data['error_message'];
    if (isUndefinedOrNull(_response_data_error_message)) {
      throw new Error('extractDataFromAxiosResponse(): _response_status is not 200');
    } else {
      throw new Error(_response_data_error_message);
    }
  }

  const _response_data_data = _response_data['data'];
  if (isUndefinedOrNull(_response_data_data)) {
    throw new Error('extractDataFromAxiosResponse(): _response_data_data is undefined or null');
  }

  return _response_data_data;
};
