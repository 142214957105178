import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';
import authentication, { AuthenticationState } from './auth.reducer';
import form, { FormState } from './form.reducer';
import mint, { MintState } from './mint.reducer';
import qrModal, { QrModalState } from './qr-modal.reducer';

/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly loadingBar: any;
  readonly authentication: AuthenticationState;
  readonly form: FormState;
  readonly mint: MintState;
  readonly qrModal: QrModalState;
}

const rootReducer = combineReducers<IRootState>({
  loadingBar,
  authentication,
  form,
  mint,
  qrModal
});

export default rootReducer;
