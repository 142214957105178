import React from 'react';

type AdvancedProps = {
  onSetCid: (value: string) => void;
};

export const Advanced = (props: AdvancedProps) => {
  const { onSetCid } = props;

  const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    onSetCid(target.value);
  };

  return (
    <>
      <input className="ipfs-input" placeholder="IPFS CID" onInput={handleInput} />
    </>
  );
};
