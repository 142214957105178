import React, { useState } from 'react';
import { Checkbox } from '../../../../components/shared/checkbox/checkbox';
import step3Image from '../../../../assets/images/step-3.png';
import faucet from '../../../../assets/images/faucet.png';
import faucetSuccess from '../../../../assets/images/faucet-success.png';
import mintSuccess from '../../../../assets/images/mint-success.png';
import wheel1 from '../../../../assets/images/wheel-1.svg';
import wheel2 from '../../../../assets/images/wheel-2.svg';
import wheel3 from '../../../../assets/images/wheel-3.svg';

import {
  setupXUMMPayloadEventHandlers,
  XUMMPayloadGraphicHandler
} from '../../../../scripts/NFT_builder.mjs';
import {
  getFaucetState,
  getFundedNFTs,
  getMintedTokens,
  handleELSFaucet,
  handleFunding,
  handleNFTokensMinting
} from '../../../../scripts/NFT_building_handler.mjs';
import { toast } from 'react-toastify';
import { Modal } from '../../../../components/shared/modal';
import { isMobileUser } from '../../../../scripts/utils/utils.mjs';
import { useDispatch } from 'react-redux';
import { clearAuthentication } from '../../../../reducers/auth.reducer';
import { handleXUMMSignFlow, isXumm } from '../../../../scripts/xumm_handler.mjs';
import { WrappedEventTarget } from '../../../../scripts/classes/WrappedEventTarget.mjs';

type Tstate = {
  status: 'NONE' | 'FAUCET' | 'PAYING' | 'MINTING';
  visible: boolean;
  loading: boolean;
  success: boolean;
  error: boolean;
  faucetDone: boolean;
  minted: {
    homp: string;
    token: string[];
  };
};

type Tqr = {
  open: boolean;
  qrUrl: string | null;
  signUrl: string | null;
};

export const Step3 = () => {
  const dispatch = useDispatch();
  const [agree, setAgree] = useState<boolean>(false);
  const [state, setState] = useState<Tstate>({
    status: 'NONE',
    visible: false,
    loading: false,
    success: false,
    error: false,
    faucetDone: true,
    minted: {
      homp: null as unknown as string,
      token: null as unknown as string[]
    }
  });
  const [qr, setQr] = useState<Tqr>({
    open: false,
    qrUrl: null,
    signUrl: null
  });

  const BITHOMP_XLS20_PREFIX = 'https://xls20.bithomp.com/explorer/';
  const VISUALIZER_XLS20_PREFIX = 'https://xls20.xrplnft.art/Mainnet/nft-data/';

  const errorMessages: any = {
    USER_NOT_AUTHENTICATED: 'Session expired, please login.',
    FAUCET_NOT_AUTHORIZED: 'Faucet not authorized',
    UNFUNDED_NFTS: 'No NFTs where funded',
    NFT_SERIAL_UNAVAILABLE: 'NFT serial unavailable'
  };

  const getErrorMessage = (msg: any) => {
    if (msg in errorMessages) {
      return errorMessages[msg];
    }

    return msg;
  };

  const onError = (errorMessage: any) => {
    console.log('ERROR', errorMessage.message);

    setState({
      ...state,
      status: 'NONE',
      loading: false
    });

    if (errorMessage?.message === 'USER_NOT_AUTHENTICATED') {
      dispatch(clearAuthentication());
    }

    toast.error(
      errorMessage?.message ? getErrorMessage(errorMessage.message) : 'Some error occured'
    );
  };

  const onPayloadCreated = (data: any) => {
    if (isXumm()) {
      handleXUMMSignFlow(data.payload_created.uuid);
    } else if (!isMobileUser()) {
      setQr({
        ...qr,
        open: true,
        qrUrl: data.payload_created.refs.qr_png
      });
    } else {
      setQr({
        ...qr,
        open: true,
        signUrl: data.payload_created.next.always
      });
    }
  };

  const onPayloadSignedOrError = (data: any) => {
    console.log('Payload said -->', data);
    setQr({
      ...qr,
      open: false,
      qrUrl: null
    });
  };

  const onFaucet = async () => {
    setState({
      ...state,
      status: 'FAUCET',
      loading: true
    });

    console.log('faucet');

    const faucet_event_target = new WrappedEventTarget();

    faucet_event_target.addEventHandler('xumm_payload_created', (data: any) => {
      onPayloadCreated(data);
    });

    faucet_event_target.addEventHandler('xumm_payload_signed', function (data: any) {
      onPayloadSignedOrError(data);
    });

    faucet_event_target.addEventHandler('xumm_payload_error', function (data: any) {
      onPayloadSignedOrError(data);
    });

    try {
      await handleELSFaucet(faucet_event_target);
      onFaucetSuccess();
    } catch (e: any) {
      e.message in errorMessages ? onError(e) : checkFaucet();
    }
  };

  const checkFaucet = async () => {
    console.log('check faucet');

    try {
      await getFaucetState();
      onFaucetSuccess();
    } catch (e: any) {
      onError(e);
    }
  };

  const onFaucetSuccess = () => {
    console.log('faucet sucess');

    setState({
      ...state,
      status: 'FAUCET',
      loading: false,
      success: true
    });
  };

  const onFaucetClose = () => {
    setState({
      ...state,
      status: 'NONE',
      success: false,
      faucetDone: true
    });
  };

  const onMint = async () => {
    setState({
      ...state,
      status: 'MINTING',
      success: false,
      error: false,
      loading: true
    });

    const minting_event_target = new WrappedEventTarget();

    minting_event_target.addEventHandler('xumm_payload_created', (data: any) => {
      onPayloadCreated(data);
    });

    minting_event_target.addEventHandler('xumm_payload_signed', function (data: any) {
      onPayloadSignedOrError(data);
    });

    minting_event_target.addEventHandler('xumm_payload_error', function (data: any) {
      onPayloadSignedOrError(data);
    });

    try {
      const res = await handleNFTokensMinting(minting_event_target);
      minting_event_target.clearEventHandlers();
      res.data?.has_been_minted && res.data?.user_address && res.data?.tokens
        ? onMintSuccess(res.data)
        : onError('Mint error');
    } catch (e: any) {
      console.log("onMint(): ", e);
      checkMintedTokes();
    }
  };

  const checkMintedTokes = async () => {
    try {
      const res = await getMintedTokens();
      res.data?.has_been_minted && res.data?.user_address && res.data?.tokens
        ? onMintSuccess(res.data)
        : onError('Mint error');
    } catch (e: any) {
      console.log("checkMintedTokes(): ", e);
      onError(e);
    }
  };

  const onMintSuccess = (data: any) => {
    setState({
      ...state,
      status: 'MINTING',
      loading: false,
      success: true,
      minted: {
        homp: BITHOMP_XLS20_PREFIX + data?.user_address,
        token: data?.tokens.map(
          (token: any) => VISUALIZER_XLS20_PREFIX + data?.user_address + '/' + token
        )
      }
    });
  };

  const onFund = async () => {
    setState({
      ...state,
      status: 'PAYING',
      success: false,
      error: false,
      loading: true
    });

    const funding_event_target = new WrappedEventTarget();

    funding_event_target.addEventHandler('xumm_payload_created', (data: any) => {
      onPayloadCreated(data);
    });
    funding_event_target.addEventHandler('xumm_payload_signed', function (data: any) {
      onPayloadSignedOrError(data);
    });

    funding_event_target.addEventHandler('xumm_payload_error', function (data: any) {
      onPayloadSignedOrError(data);
    });

    try {
      const res = await handleFunding(funding_event_target);
      res.data?.funded_NFTs > 0 ? onMint() : onError('Error funding');
      funding_event_target.clearEventHandlers();
    } catch (e: any) {
      checkFunds();
    }
  };

  const checkFunds = async () => {
    try {
      const res = await getFundedNFTs();
      res.data?.funded_NFTs > 0 ? onMint() : onError('Error funding');
    } catch (e) {
      onError(e);
    }
  };

  return (
    <>
      <div className="lines" />
      {state.status === 'NONE' && (
        <>
          <div className="left-col">
            <div className="hero img-content">
              <h1 className="step-title">Step three</h1>
              <img src={step3Image} className="sm-hidden img-fit-contain step-3-image" />
            </div>
          </div>
          <div className="right-col form">
            <div className="step-form">
              <div className="mint-price">
                <span>This operation will cost you </span>
                <div className="price"> 3ELS </div>
                { /* <span>You can get free ELS from the Faucet</span> */ }
              </div>

              <div className={`step-actions ${state.faucetDone ? 'faucet-done' : ''}`}>
                <button className="button md dark rounded" disabled={!agree} onClick={onFund}>
                  Mint
                </button>
                {!state.faucetDone && (
                  <button className="button md dark rounded" onClick={onFaucet}>
                    ELS Faucet
                  </button>
                )}
              </div>
              <div className="acceptance">
                <Checkbox checked={agree} onCheck={() => setAgree(!agree)} />
                <p>
                  You agree that any information uploaded to the XRPL NFT Minter will not contain
                  material subject to copyright or other proprietary rights, unless you have
                  necessary permission or are otherwise legally entitled to post the material.
                </p>
              </div>
            </div>
          </div>
        </>
      )}

      {state.status === 'FAUCET' && (
        <>
          <div className="left-col">
            <div className="hero">
              {state.loading && (
                <h1 className="action-title loading">We are sending you some ELS...</h1>
              )}
              {state.success && (
                <>
                  <h1 className="action-title success">ELS sent!</h1>
                  <button className="button md dark rounded" onClick={onFaucetClose}>
                    Continue to mint your NFT
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="right-col mint">
            <div className="image-wrapper mint">
              {state.loading && <img src={faucet} className="img-fit-contain" />}
              {state.success && <img src={faucetSuccess} className="img-fit-contain" />}
            </div>
          </div>
        </>
      )}

      {(state.status === 'PAYING' || state.status === 'MINTING') && (
        <>
          <div className="left-col">
            <div className="hero">
              {state.status === 'PAYING' && <h1 className="action-title loading">Burning...</h1>}
              {state.status === 'MINTING' && (
                <>
                  {state.loading && (
                    <div>
                      <h2 className="action-title small success">Burning successfull!</h2>
                      <h1 className="action-title loading"> You&apos;re minting... </h1>
                    </div>
                  )}
                  {state.success && (
                    <>
                      <h1 className="action-title success">Minting successfull!</h1>
                      <a
                        href={state.minted?.token[0] || ''}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="button md dark rounded"
                      >
                        Visualize your NFT
                      </a>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div
            className={`right-col ${
              state.status === 'PAYING' || (state.status === 'MINTING' && state.loading)
                ? 'mint-wheels'
                : 'mint'
            } `}
          >
            {(state.status === 'PAYING' || (state.status === 'MINTING' && state.loading)) && (
              <div className="wheels">
                <div className="wheel wheel-1">
                  <img src={wheel1} />
                </div>
                <div className="wheel wheel-2">
                  <img src={wheel2} />
                </div>
                <div className="wheel wheel-3">
                  <img src={wheel3} />
                </div>
              </div>
            )}

            {state.status === 'MINTING' && state.success && (
              <div className="image-wrapper mint">
                <img src={mintSuccess} className="img-fit-contain" />
              </div>
            )}
          </div>
        </>
      )}

      {qr.open && (
        <Modal size="xs">
          {qr.qrUrl && (
            <div className="qr-modal">
              <span>Scan me</span>
              <img src={qr.qrUrl} />
            </div>
          )}
          {qr.signUrl && (
            <div className="qr-modal">
              <p>Sign transaction on XUMM app</p>
              <a
                className="button sm solid dark rounded"
                href={qr.signUrl}
                target="_blank"
                rel="noreferrer noopener"
              >
                Sign
              </a>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};
