import React from 'react';
import { Link } from 'react-router-dom';
import { useWindowSize } from '../../hook/useWindowSize';
import { openExternal } from '../../utils';
import { Icon } from '../shared/icons';
import circleColored from '../../assets/images/cerchi-colorati.svg';
import circle1 from '../../assets/images/cerchio1_img-01.svg';
import circle2 from '../../assets/images/cerchio2_img-01.svg';
import { FooterContent } from '../shared/footer-content/footer-content';
import { NavItem } from './nav-item';

type NavMobileProps = {
  onClose: () => void;
};

export const NavMobile = (props: NavMobileProps) => {
  const size = useWindowSize();

  return (
    <div className="navigation-mobile" style={{ height: `${size.height}px` }}>
      <div className="navigation-close">
        <button className="button close" onClick={props.onClose}>
          <Icon content="close" size="sm" fill="#ffffff" />
        </button>
      </div>
      <div className="nav">
        <NavItem to="/how-it-works" text="HOW IT WORKS" onClick={props.onClose} />
        <NavItem to="/create" text="CREATE YOUR NFT" onClick={props.onClose} />
        { /*<NavItem to="/faqs" text="FAQs" onClick={props.onClose} /> */}
        <NavItem to="/support" text="Support" onClick={props.onClose} />
        <a
          href="https://www.aesthetes.art"
          onClick={(e) => {
            e.preventDefault();
            openExternal('https://www.aesthetes.art');
          }}
          className="button md orange rounded"
        >
          Aesthetes
        </a>
      </div>

      <FooterContent />

      {/* <div className="image-wrapper homepage">
        <img src={circle1} className="circle-1" />
        <img src={circle2} className="circle-2" />
        <img src={circleColored} />
      </div> */}
    </div>
  );
};
