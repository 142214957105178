import { isUndefinedOrNull } from '../utils/utils.mjs';

export class WrappedEventTarget {
  #event_target;
  #event_handlers;

  constructor() {
    this.#event_target = new EventTarget();
    this.#event_handlers = {};
  }

  dispatchEvent(event_name, event_details = {}) {
    this.#event_target.dispatchEvent(
      new CustomEvent(event_name, {
        detail: event_details
      })
    );
  }

  addEventHandler(event_name, handling_function) {
    const handling_function_wrapper = async function (event) {
      const detail = event['detail'];
      if (isUndefinedOrNull(detail)) {
        throw new Error(event_name + ' handler: detail is undefined or null');
      }

      handling_function(detail);
    };

    //add the event listener
    this.#event_target.addEventListener(event_name, handling_function_wrapper);

    //add the event to the handled events subobject
    this.#event_handlers[event_name] = handling_function_wrapper;
  }

  removeEventHandler(event_name) {
    if (isUndefinedOrNull(this.#event_handlers[event_name])) {
      return;
    }

    //remove the event listener
    this.#event_target.removeEventListener(event_name, this.#event_handlers[event_name]);

    //remove the event to the handled events subobject
    delete this.#event_handlers[event_name];
  }

  clearEventHandlers() {
    Object.keys(this.#event_handlers).every((event_name) => {
      //remove the event listener
      this.#event_target.removeEventListener(event_name, this.#event_handlers[event_name]);

      //remove the event to the handled events subobject
      delete this.#event_handlers[event_name];

      return true; //continue
    });
  }
}
