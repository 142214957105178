declare global {
  interface Window {
    ReactNativeWebView: any;
  }
}
export function openExternal(
  url: string,
  target: '_blank' | '_parent' | '_self' | '_top' = '_blank'
) {
  if (isXumm()) {
    handleXUMMRedirection(url);
  } else {
    window.open(url, target);
  }
}

const xapp_parameters: any = null;
const xumm_parameters: any = null;

export const isXumm = function () {
  if (/xumm/i.test(navigator.userAgent)) {
    if (xapp_parameters === null) {
      // if xapp_parameters has not been already initialized
      extractXappParameters();
      // console.log("isXumm(): xapp_parameters: ", xapp_parameters)
    }
    return true;
  }

  return false;
};

export const extractXappParameters = function () {
  const url_parameters = retrieveUrlParameters();

  const xAppToken_array = url_parameters['xAppToken'];
  if (isUndefinedOrNull(xAppToken_array) || xAppToken_array.length === 0) {
    return false;
  }
  xapp_parameters['xAppToken'] = xAppToken_array[0];

  const xAppStyle_array = url_parameters['xAppStyle'];
  if (isUndefinedOrNull(xAppStyle_array) || xAppStyle_array.length === 0) {
    xumm_parameters['xAppStyle'] = 'LIGHT'; // LIGHT is the default
    return true;
  }
  xapp_parameters['xAppStyle'] = xAppStyle_array[0];

  return true;
};

export const retrieveUrlParameters = function () {
  const qd: any = {};
  if (location.search) {
    location.search
      .substr(1)
      .split('&')
      .forEach(function (item) {
        const s = item.split('=');
        const k = s[0];
        const v = s[1] && decodeURIComponent(s[1]); //  null-coalescing / short-circuit
        // (k in qd) ? qd[k].push(v) : qd[k] = [v]
        (qd[k] = qd[k] || []).push(v); // null-coalescing / short-circuit
      });
  }

  return qd;
};

export const isUndefinedOrNull = function (_value: any) {
  return _value === undefined || _value === null;
};

export const handleXUMMRedirection = function (_url: any) {
  if (isUndefinedOrNull(_url) || _url.length === 0) {
    return;
  }

  const XUMM_command_obj = {
    command: 'openBrowser',
    url: _url
  };

  postXUMMCommand(XUMM_command_obj);
};

function postXUMMCommand(XUMM_command_obj: any) {
  if (typeof window.ReactNativeWebView !== 'undefined') {
    window.ReactNativeWebView.postMessage(JSON.stringify(XUMM_command_obj));
  }
}
