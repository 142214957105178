import { isUndefinedOrNull, retrieveUrlParameters, deepCopyObject } from './utils/utils.mjs';

var xapp_parameters = null;

export const isXumm = function () {
  if (/xumm/i.test(navigator.userAgent)) {
    if (xapp_parameters === null) {
      //if xapp_parameters has not been already initialized
      extractXappParameters();
      //console.log("isXumm(): xapp_parameters: ", xapp_parameters)
    }
    return true;
  }

  return false;
};

export const getXappParameters = function () {
  if(isUndefinedOrNull(xapp_parameters)){
    return null;
  }
  console.log("getXappParameters(): Object.keys(xapp_parameters).length: ", Object.keys(xapp_parameters).length);
  if(Object.keys(xapp_parameters).length === 0){
    return null;
  }
  
  return deepCopyObject(xapp_parameters);
};

function postXUMMCommand(XUMM_command_obj) {
  if (typeof window.ReactNativeWebView !== 'undefined') {
    window.ReactNativeWebView.postMessage(JSON.stringify(XUMM_command_obj));
  }
}
export const handleXUMMRedirection = function (_url) {
  if (isUndefinedOrNull(_url) || _url.length === 0) {
    return;
  }

  const XUMM_command_obj = {
    command: 'openBrowser',
    url: _url
  };

  postXUMMCommand(XUMM_command_obj);
};
export const handleXUMMSignFlow = function (payload_uuid) {
  const XUMM_command_obj = {
    command: 'openSignRequest',
    uuid: payload_uuid
  };

  postXUMMCommand(XUMM_command_obj);
};

async function callRestApiWrapper(network_handler, method, endpoint_url, data = {}) {
  const x_app_token = xumm_parameters['xAppToken'];

  const _data = {
    ...data,
    xAppToken: x_app_token
  };
  return network_handler.callRestApi(method, endpoint_url, _data);
}

export const extractXappParameters = function () {
  const url_parameters = retrieveUrlParameters();
  console.log("extractXappParameters(): url_parameters: ", url_parameters);
  
  const xAppToken_array = url_parameters['xAppToken'];
  if (isUndefinedOrNull(xAppToken_array) || xAppToken_array.length === 0) {
    return false;
  }
  
  if(isUndefinedOrNull(xapp_parameters)){
    xapp_parameters = {};
  }
  xapp_parameters['x_app_token'] = xAppToken_array[0];

  const xAppStyle_array = url_parameters['xAppStyle'];
  if (isUndefinedOrNull(xAppStyle_array) || xAppStyle_array.length === 0) {
    xumm_parameters['xAppStyle'] = 'LIGHT'; //LIGHT is the default
    return true;
  }
  xapp_parameters['x_app_style'] = xAppStyle_array[0];

  return true;
};
