import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useWindowSize } from './hook/useWindowSize';
import Create from './pages/create';
import Faqs from './pages/faqs';
import Homepage from './pages/homepage';
import HowItWorks from './pages/how-it-works';
import NotFound from './pages/not-found';
import Support from './pages/support';

export const AppRoutes = () => {
  const size = useWindowSize();

  const dif = (size.width || window.innerWidth) > 1023 ? 240 : 80;
  const wHeight = size.height || window.innerHeight;
  const height = wHeight - dif;

  useEffect(() => {}, []);

  return (
    <div
      className="app-content"
      style={{
        height: `${height}px`
      }}
    >
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Homepage />} />
        <Route path="/create" element={<Create />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/support" element={<Support />} />
      </Routes>
      <div className="app-backdrop" />
    </div>
  );
};
