import React from 'react';
import { FooterContent } from '../shared/footer-content/footer-content';

export const Footer = () => {
  return (
    <div className="footer">
      <FooterContent />
    </div>
  );
};
