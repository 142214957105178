import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../reducers';
import { resetForm } from '../../../reducers/form.reducer';
import { Step1 } from './step-1/step-1';
import { Step2 } from './step-2/step-2';
import { Step3 } from './step-3/step-3';

export const Steps = () => {
  const form = useSelector((state: IRootState) => state.form);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  return (
    <>
      {form.step === 1 && <Step1 />}
      {form.step === 2 && <Step2 />}
      {form.step === 3 && <Step3 />}
    </>
  );
};
