export const ACTION_TYPES = {
  SHOW_QR: 'qr-modal/SHOW_QR',
  HIDE_QR: 'qr-modal/HIDE_QR'
};

const initialState = {
  qrVisible: false,
  qrUrl: null as unknown as string
};

export type QrModalState = Readonly<typeof initialState>;

// Reducer

export default (state: QrModalState = initialState, action: any): QrModalState => {
  switch (action.type) {
    case ACTION_TYPES.SHOW_QR:
      return {
        ...state,
        qrVisible: true,
        qrUrl: action.payload
      };
    case ACTION_TYPES.HIDE_QR:
      return {
        ...state,
        qrVisible: false,
        qrUrl: null as unknown as string
      };

    default:
      return state;
  }
};

export const showQr = (qrUrl: string) => (dispatch: any) => {
  dispatch({
    type: ACTION_TYPES.SHOW_QR,
    payload: qrUrl
  });
};

export const hideQr = () => (dispatch: any) => {
  dispatch({
    type: ACTION_TYPES.HIDE_QR
  });
};
