import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Icon } from '../../../../components/shared/icons';
import { useWindowSize } from '../../../../hook/useWindowSize';

type UploadFileProps = {
  onSelect: (file: File | null) => void;
};

type TUploadState = {
  file: File | null;
  fileType: 'image' | 'video' | 'audio' | 'file' | null;
  error: boolean;
  errorMessage: string | null;
};

const defaultSelectState: TUploadState = {
  fileType: null,
  error: false,
  errorMessage: null,
  file: null
};

export const SelectFile = (props: UploadFileProps) => {
  const { onSelect } = props;
  const [selectState, setSelectState] = useState<TUploadState>(defaultSelectState);
  const size = useWindowSize();

  const onDrop = useCallback((dropped: File[]) => {
    if (dropped.length > 1) {
      setSelectState({
        ...selectState,
        error: true,
        errorMessage: 'Only one file is allowed'
      });
    } else {
      setSelectState({
        error: false,
        errorMessage: null,
        file: dropped[0],
        fileType: getFileType(dropped[0])
      });
      onSelect(dropped[0]);
    }
  }, []);

  const getFileType = (file: File) => {
    if (file.type.includes('image')) {
      return 'image';
    }
    if (file.type.includes('video')) {
      return 'video';
    }
    if (file.type.includes('audio')) {
      return 'audio';
    }

    return 'file';
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onRemoveFile = () => {
    setSelectState({
      ...selectState,
      file: null,
      fileType: null
    });
    onSelect(null);
  };

  return (
    <>
      {selectState.file ? (
        <>
          <div className="file-preview">
            <div className="file-type">
              {selectState.fileType === 'image' && <Icon content="image" size="md" />}
              {selectState.fileType === 'video' && <Icon content="video" size="md" />}
              {selectState.fileType === 'audio' && <Icon content="audio" size="md" />}
              {selectState.fileType === 'file' && <Icon content="file" size="md" />}
            </div>
            <div className="file-name">{selectState.file.name}</div>
            <button className="button" onClick={onRemoveFile}>
              <Icon content="close" />
            </button>
          </div>
        </>
      ) : (
        <>
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            <div className="label">
              {size.width && size.width > 1023 ? (
                <>
                  {isDragActive ? (
                    <p>Drop the file ...</p>
                  ) : (
                    <p>
                      Drag&Drop
                      <br /> or <span>click to select</span>
                    </p>
                  )}
                </>
              ) : (
                <>
                  <p>Choose your file</p>
                </>
              )}
            </div>
          </div>
          {selectState.error && <>{selectState.errorMessage}</>}
        </>
      )}
    </>
  );
};
