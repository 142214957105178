import React from 'react';
import { Icon } from '../icons';

type TCheckbox = {
  checked: boolean;
  disabled?: boolean;
  onCheck: () => void;
};

export const Checkbox = (props: TCheckbox) => {
  const { checked, disabled, onCheck } = props;

  return (
    <div className="checkbox">
      <button className={checked ? 'checked' : ''} disabled={disabled} onClick={onCheck}>
        {checked && <Icon content="checked" />}
      </button>
    </div>
  );
};
