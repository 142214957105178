import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './scroll-top';
import { useWindowSize } from './hook/useWindowSize';
import { AppRoutes } from './routes';
import Header from './components/header';
import Footer from './components/footer';
import { ToastContainer, Slide } from 'react-toastify';
import './App.scss';
import 'simplebar/dist/simplebar.min.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const size = useWindowSize();

  // useEffect(() => {
  //   const s = document.createElement('script');
  //   s.src = 'https://remotejs.com/agent/agent.js';
  //   s.setAttribute('data-consolejs-channel', '4447b657-c207-3e96-5dfb-3c16427fcb7d');
  //   document.head.appendChild(s);
  // }, []);

  return (
    <Router>
      <ScrollToTop>
        <div
          className="app-wrapper"
          style={{
            width: `${size.width}px`,
            height: `${size.height}px`
          }}
        >
          <Header />
          <AppRoutes />
          <Footer />
        </div>
      </ScrollToTop>
      <ToastContainer position="bottom-center" transition={Slide} />
    </Router>
  );
}

export default App;
