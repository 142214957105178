import React, { ReactComponentElement, ReactElement } from 'react';
import './icons.scss';

import { ReactComponent as Twitter } from './icon-twitter.svg';
import { ReactComponent as Instagram } from './icon-instagram.svg';
import { ReactComponent as Facebook } from './icon-facebook.svg';
import { ReactComponent as Youtube } from './icon-youtube.svg';
import { ReactComponent as Linkedin } from './icon-linkedin.svg';
import { ReactComponent as Close } from './icon-close.svg';
import { ReactComponent as Checked } from './icon-checked.svg';
import { ReactComponent as Image } from './icon-image.svg';
import { ReactComponent as Video } from './icon-video.svg';
import { ReactComponent as Audio } from './icon-audio.svg';
import { ReactComponent as File } from './icon-file.svg';
import { ReactComponent as ArrowDown } from './icon-arrow-down.svg';

const Icons: { [key in IconsEnum]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } = {
  twitter: Twitter,
  instagram: Instagram,
  facebook: Facebook,
  youtube: Youtube,
  linkedin: Linkedin,
  close: Close,
  checked: Checked,
  image: Image,
  video: Video,
  audio: Audio,
  file: File,
  arrowDown: ArrowDown
};

type IconProps = {
  content: IconsEnum;
  fill?: string;
  className?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
};

export type IconsEnum =
  | 'twitter'
  | 'instagram'
  | 'facebook'
  | 'youtube'
  | 'linkedin'
  | 'close'
  | 'checked'
  | 'image'
  | 'video'
  | 'audio'
  | 'file'
  | 'arrowDown';

export const Icon = (props: IconProps) => {
  const { content, className, size = 'sm', fill = '#000000' } = props;

  const Svg = Icons[content];
  return (
    <div className={`Icon ${size} ${className || ''}`}>
      <Svg fill={fill} />
    </div>
  );
};
