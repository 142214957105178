import axios from 'axios';
import { API_URL } from '../config/constants';
import { FAILURE, REQUEST, SUCCESS } from './action-type.util';

export const ACTION_TYPES = {
  LOGIN: 'authentication/LOGIN',
  LOGOUT: 'authentication/LOGOUT',
  CLEAR_AUTH: 'authentication/CLEAR_AUTH',
  SHOW_QR: 'authentication/SHOW_QR',
  CLEAR_QR: 'authentication/CLEAR_QR',
  SET_ADDRESS: 'authentication/SET_ADDRESS'
};

const initialState = {
  loading: false,
  isAuthenticated: false,
  loginSuccess: false,
  loginError: false,
  logoutSuccess: false,
  logoutError: false,
  address: null as unknown as string,
  showQr: false,
  imgQr: null as unknown as string
};

export type AuthenticationState = Readonly<typeof initialState>;

// Reducer

export default (state: AuthenticationState = initialState, action: any): AuthenticationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.LOGIN):
    case REQUEST(ACTION_TYPES.LOGOUT):
      return {
        ...state,
        loading: true
      };
    case FAILURE(ACTION_TYPES.LOGIN):
      return {
        ...initialState,
        loginError: true
      };
    case FAILURE(ACTION_TYPES.LOGOUT):
      return {
        ...initialState,
        logoutError: true
      };

    case SUCCESS(ACTION_TYPES.LOGIN):
      return {
        ...state,
        loading: false,
        loginError: false,
        loginSuccess: true
      };
    case SUCCESS(ACTION_TYPES.LOGOUT):
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        address: null as unknown as string
      };
    case ACTION_TYPES.LOGOUT: {
      return initialState;
    }

    case ACTION_TYPES.CLEAR_AUTH:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        address: null as unknown as string
      };

    case ACTION_TYPES.SHOW_QR:
      return {
        ...state,
        showQr: true,
        imgQr: action.payload
      };
    case ACTION_TYPES.CLEAR_QR:
      return {
        ...state,
        showQr: false,
        imgQr: null as unknown as string
      };

    case ACTION_TYPES.SET_ADDRESS:
      return {
        ...state,
        address: action.payload,
        isAuthenticated: true
      };

    default:
      return state;
  }
};

export const sleep = async function (ms: any) {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const login: (procedure: boolean) => void = (procedure) => async (dispatch: any) => {
  const data = {
    start_login_proc: procedure
  };

  const result = await dispatch({
    type: ACTION_TYPES.LOGIN,
    payload: axios.post(`/endpoints/auth/checkLoginState`, data)
  });

  const resultObj = result.value.data.data;

  if (!procedure) {
    await sleep(1 * 1000);
  }

  if (!resultObj.is_logged_in) {
    if (resultObj?.payload_created) {
      dispatch(showQr(resultObj.payload_created.refs.qr_png));
      dispatch(initWs(resultObj.payload_created.refs.websocket_status));
    }
  }
  if (resultObj.is_logged_in) {
    dispatch(setAddress(resultObj.address));
  }
};

export const logout: () => void = () => async (dispatch: any) => {
  dispatch({
    type: ACTION_TYPES.LOGOUT,
    dispatch: await axios.post('/endpoints/auth/logout')
  });
};

export const clearAuthentication = () => (dispatch: any) => {
  dispatch({
    type: ACTION_TYPES.CLEAR_AUTH
  });
};

const showQr = (qrUrl: string) => (dispatch: any) => {
  dispatch({
    type: ACTION_TYPES.SHOW_QR,
    payload: qrUrl
  });
};

const clearQr = () => (dispatch: any) => {
  dispatch({
    type: ACTION_TYPES.CLEAR_QR
  });
};

export const setAddress = (address: string) => (dispatch: any) => {
  dispatch({
    type: ACTION_TYPES.SET_ADDRESS,
    payload: address
  });
};

const initWs: (url: string) => void = (url) => (dispatch: any) => {
  console.log(url);

  // eslint-disable-next-line
  const conn = new WebSocket(url);
  conn.onopen = () => {
    console.log('Connection open');
  };
  conn.onmessage = (event) => {
    const eventData = JSON.parse(event.data);
    console.log('WS data', eventData);

    if (eventData?.expired) {
      conn.close();
      dispatch(clearQr());
    }

    if (eventData?.signed) {
      dispatch(login(false));
    }
  };
};
