import { FormState } from '../../../../reducers/form.reducer';
import {
  setNFTContent,
  setNFTContentMetadata,
  setNFTSettings
} from '../../../../scripts/NFT_building_handler.mjs';

export const generateMintObj = async (form: FormState) => {
  const obj = {
    metadata: {
      mode: form.step2.content_cid ? 'cid' : 'upload',
      cid: form.step2.content_cid || null,
      name: form.step2.name || null,
      author: form.step2.author || null,
      description: form.step2.description || null
    },
    content: {
      mode: form.step1.cid ? 'cid' : 'upload',
      cid: form.step1.cid || null,
      file: form.step1.file || null
    },

    settings: {
      transfer_fee: form.step2.transfer_fee,
      flags: form.step2.flags,
      taxon: 0
    }
  };

  const content = await setNFTContent(obj['content']);
  const metadata = await setNFTContentMetadata(obj['metadata']);
  const settings = await setNFTSettings(obj['settings']);

  if (content['is_ok'] && metadata['is_ok'] && settings['is_ok']) {
    return true;
  }

  return false;
};
