import React from 'react';

export const Support = () => {
  return (
    <div className="support">
      <div>
        For support or informations please contact us at &nbsp;
        <a href="mailto: info@aesthetes.art">info@aesthetes.art</a>
      </div>
    </div>
  );
};
