import React from 'react';
import { Icon } from '../icons';

export const FooterContent = () => {
  return (
    <>
      <div className="footer-content">
        <div className="copy">@2022 Aesthetes srl. All rights reserved</div>
        <div className="social">
          <a href="https://twitter.com/aesthetes_art" target="_blank" rel="noopener noreferrer">
            <Icon content="twitter" fill="#ffffff" />
          </a>
          <a
            href="https://www.instagram.com/aesthetes_fineart/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon content="instagram" fill="#ffffff" />
          </a>
          <a
            href="https://www.facebook.com/aesthetes.fineart/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon content="facebook" fill="#ffffff" />
          </a>
          <a
            href="https://www.linkedin.com/company/71714406/admin/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon content="linkedin" fill="#ffffff" />
          </a>
          <a href="https://www.youtube.com/c/Aesthetes" target="_blank" rel="noopener noreferrer">
            <Icon content="youtube" fill="#ffffff" />
          </a>
        </div>
      </div>
    </>
  );
};
