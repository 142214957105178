import { isUndefinedOrNull, dispatchEvent } from './utils.mjs';

export const handleXUMMPayloadWS = async function (xumm_ws_connection, event_target) {
  var obj_to_return = null;
  var _promise = new Promise(function (resolve, reject) {
    xumm_ws_connection.onerror = async function (error) {
      //TODO UNTESTED
      event_target.dispatchEvent('xumm_payload_error', error);
      // reject(error);
      resolve(error);
      return;
    };
    xumm_ws_connection.onopen = async function () {
      //console.log("XUMM wss opened");
      let has_been_opened = false;
      let has_been_signed = false;

      xumm_ws_connection.onmessage = async function (event) {
        let event_data = JSON.parse(event['data']);
        console.log('event_data: ', event_data);

        let expired = event_data['expired'];
        if (!isUndefinedOrNull(expired) && expired) {
          console.log('Expired!');
          event_target.dispatchEvent('xumm_payload_expired');

          let obj_to_return = {
            signed: false,
            expired: true
          };
          resolve(obj_to_return);
          return;
        }

        if (!has_been_opened) {
          //if it has not been opened before
          let opened = event_data['opened'];
          if (!isUndefinedOrNull(opened)) {
            //if it's an opening notification
            has_been_opened = opened;

            if (opened) {
              event_target.dispatchEvent('xumm_payload_opened');
            }
          }
        } else if (!has_been_signed) {
          //if it has been opened already, but has not yet been signed
          let signed = event_data['signed'];
          if (!isUndefinedOrNull(signed)) {
            //has_been_signed = signed;
            xumm_ws_connection.close();
            console.log('XUMM wss closed');

            if (!signed) {
              let obj_to_return = {
                signed: false,
                expired: false
              };
              event_target.dispatchEvent('xumm_payload_aborted');
              resolve(obj_to_return);
              return;
            }
            event_target.dispatchEvent('xumm_payload_signed');

            let obj_to_return = {
              signed: true,
              expired: false,
              event_data: event_data
            };
            resolve(obj_to_return);
            return;
          }
        } else {
          //if it has been opened and signed
          //ignore
          console.log('not closing WSS connection');
        }
      };
    };
  });

  const xumm_payload_ws_result = await _promise;
  return xumm_payload_ws_result;
};
