import {
  isUndefinedOrNull,
  isString,
  isFile,
  exportedFunctionWrapper,
  doCountedPolling,
  JSONBeautify
} from './utils/utils.mjs';

import {
  callRestApi,
  extractDataFromAxiosResponse,
  setEndpointsURL
} from './utils/network_utils.mjs';

import {
  setEndpointsURL as setIpfsEndpointsURL,
  uploadFile
} from './utils/ipfs_utils.mjs';

import { handleXUMMPayloadWS } from './utils/xumm_utils.mjs';

import { isXumm, extractXappParameters, getXappParameters } from './xumm_handler.mjs';

var user_address = null;

var content_metadata_obj = null;
var content_obj = null;
var NFT_settings_obj = null;

setEndpointsURL(location.origin + '/endpoints/');
//setIpfsEndpointsURL("http://127.0.0.1:5021/pinning_server/endpoints/");

export const getUserAddress = function () {
  return user_address;
};

//===========================================================STEP 1================================================================
async function _isLoggedIn() {
  var return_obj = {
    is_logged_in: false
  };

  //poll the funded_NFTs from the backend
  console.log('_isLoggedIn(): polling the login state...');
  const checkLoginState_req_data = {
    start_login_proc: false
  };
  const login_state_wrapped = await doCountedPolling(
    async function (iteration_index) {
      try {
        //check the login state but do not start a new procedure if it's not ok
        const response_auth_checkLoginState = await callRestApi(
          'POST',
          'auth/checkLoginState',
          checkLoginState_req_data
        );
        const login_state_data = extractDataFromAxiosResponse(response_auth_checkLoginState);

        console.log('LOGIN STATE DATA ------------> ', login_state_data);
        if (isUndefinedOrNull(login_state_data)) {
          throw new Error('_isLoggedIn(): login_state_data is undefined or null');
        }
        const is_logged_in = login_state_data['is_logged_in'];
        if (isUndefinedOrNull(is_logged_in)) {
          throw new Error('_isLoggedIn(): is_logged_in is undefined or null');
        }
        if (!is_logged_in) {
          throw new Error('_isLoggedIn(): is_logged_in is false');
        }
        //if the user is now logged in

        const address = login_state_data['address'];
        if (isUndefinedOrNull(address)) {
          throw new Error('_isLoggedIn(): address is undefined or null');
        }
        user_address = address; //save its address

        return address;
      } catch (error) {
        console.log('_isLoggedIn(): ', error.message);
        // throw error;
        return null;
      }
    },
    5,
    5 * 1000
  );
  console.log('_isLoggedIn(): polling ended! login_state_wrapped: ', login_state_wrapped);
  if (!login_state_wrapped['is_ok']) {
    //if no result was given from polling
    return {
      is_logged_in: false
    };
  }
  //if polling gave some result

  //extract the retrieved list
  user_address = login_state_wrapped['data'];

  return {
    is_logged_in: true,
    address: user_address
  };
}
export const isLoggedIn = async function(){
  return exportedFunctionWrapper(_isLoggedIn);
};

async function _handleLogin(sign_in_event_target) {
  var return_obj = {};
  
  console.log("_handleLogin(): isXumm(): ", isXumm());
  
  const x_app_parameters = getXappParameters();
  
  if (isXumm() && !isUndefinedOrNull(x_app_parameters)) {
    //if we're running as an xApp, and we correctly got an OTT
    //get token from url
    const x_app_token = x_app_parameters['x_app_token'];
    console.log('_handleLogin(): x_app_token: ', x_app_token);
    const xAppAuth_req_data = {
      "x_app_token": x_app_token
    };
    var response_auth_xAppAuth = await callRestApi('POST', 'auth/xAppAuth', xAppAuth_req_data);
    var xapp_auth_data = extractDataFromAxiosResponse(response_auth_xAppAuth);
    console.log('_handleLogin(): xapp_auth_data: ', xapp_auth_data);

    const is_network_ok = xapp_auth_data['is_network_ok'];
    if (isUndefinedOrNull(is_network_ok) || !is_network_ok) {
      //if the network is not correct
      return {
        is_logged_in: false,
        is_network_ok: false
      };
    }
    //if the network is correct

    return _isLoggedIn();
  }

  //check the login state and if it's not ok, start a login procedure
  //  const actual_url = window.location.href;
  var checkLoginState_req_data = {
    start_login_proc: true
    //    "actual_url": actual_url
  };
  var response_auth_checkLoginState = await callRestApi(
    'POST',
    'auth/checkLoginState',
    checkLoginState_req_data
  );
  var login_state_data = extractDataFromAxiosResponse(response_auth_checkLoginState);
  if (isUndefinedOrNull(login_state_data)) {
    throw new Error('_handleLogin(): #1: login_state_data is undefined or null');
  }
  var is_logged_in = login_state_data['is_logged_in'];
  if (isUndefinedOrNull(is_logged_in)) {
    throw new Error('_handleLogin(): #1: is_logged_in is undefined or null');
  }

  if (is_logged_in) {
    //if the user is already logged in
    const address = login_state_data['address'];
    if (isUndefinedOrNull(address)) {
      throw new Error('_handleLogin(): #1: address is undefined or null');
    }
    user_address = address; //save its address

    //and we're done
    const return_obj = {
      is_logged_in: true,
      address: address
    };
    return return_obj;
  }
  //if the user is not already logged in

  const payload_created = login_state_data['payload_created'];
  if (isUndefinedOrNull(payload_created)) {
    throw new Error('_handleLogin(): payload_created is undefined or null');
  }
  //if we correctly got a xumm payload

  //show the login procedure graphically
  sign_in_event_target.dispatchEvent('xumm_payload_created', {
    payload_created: payload_created
  }); //async

  //subscribe to that payload
  const refs = payload_created['refs'];
  if (isUndefinedOrNull(refs)) {
    throw new Error('_handleLogin(): refs is undefined or null');
  }
  const websocket_status = refs['websocket_status'];
  if (isUndefinedOrNull(websocket_status)) {
    throw new Error('_handleLogin(): websocket_status is undefined or null');
  }
  const xumm_ws_connection = new WebSocket(websocket_status);
  //throw new Error("_handleLogin(): Made up exception");
  const xumm_payload_ws_result = await handleXUMMPayloadWS(
    xumm_ws_connection,
    sign_in_event_target
  );

  //check the login state but do not start a new procedure if it's not ok
  return _isLoggedIn();
}
export const handleLogin = async function(sign_in_event_target){
  return exportedFunctionWrapper(_handleLogin, sign_in_event_target);
};

async function _handleLogout() {
  var return_obj = {};

  //check the login state and if it's ok, start a logout procedure
  var checkLoginState_req_data = {
    start_login_proc: false
  };
  var response_auth_checkLoginState = await callRestApi(
    'POST',
    'auth/checkLoginState',
    checkLoginState_req_data
  );
  var login_state_data = extractDataFromAxiosResponse(response_auth_checkLoginState);
  if (isUndefinedOrNull(login_state_data)) {
    throw new Error('_handleLogout(): #1: login_state_data is undefined or null');
  }
  var is_logged_in = login_state_data['is_logged_in'];
  if (isUndefinedOrNull(is_logged_in)) {
    throw new Error('_handleLogout(): #1: is_logged_in is undefined or null');
  }

  if (!is_logged_in) {
    //if the user is not logged in
    user_address = null; //delete the address

    //and we're done
    const return_obj = {
      is_logged_in: false
    };
    return return_obj;
  }
  //if the user is (still) logged in

  //do the logout
  var response_auth_logout = await callRestApi('POST', 'auth/logout');
  var logout_state_data = extractDataFromAxiosResponse(response_auth_logout);

  //check the login state again
  checkLoginState_req_data = {
    start_login_proc: false
  };
  response_auth_checkLoginState = await callRestApi(
    'POST',
    'auth/checkLoginState',
    checkLoginState_req_data
  );
  login_state_data = extractDataFromAxiosResponse(response_auth_checkLoginState);
  if (isUndefinedOrNull(login_state_data)) {
    throw new Error('_handleLogin(): #2: login_state_data is undefined or null');
  }
  is_logged_in = login_state_data['is_logged_in'];
  if (isUndefinedOrNull(is_logged_in)) {
    throw new Error('_handleLogin(): #2: is_logged_in is undefined or null');
  }
  if (!is_logged_in) {
    //if the user is not logged in
    user_address = null; //delete the address
  }

  return_obj['is_logged_in'] = is_logged_in;
  return return_obj;
}
export const handleLogout = async function () {
  return exportedFunctionWrapper(_handleLogout);
};
//===========================================================STEP 2================================================================
async function _getFundedNFTs() {
  //poll the funded_NFTs from the backend
  console.log('_getFundedNFTs(): polling the funded_NFTs...');
  const funded_NFTs_wrapped = await doCountedPolling(
    async function (iteration_index) {
      //ask funded_NFTs to the backend
      const response_nfts_getFundedNFTs = await callRestApi('GET', 'nfts/getFundedNFTs');
      try {
        const _funding_data = extractDataFromAxiosResponse(response_nfts_getFundedNFTs);
        const _funded_NFTs = _funding_data['funded_NFTs'];
        console.log(
          '_getFundedNFTs(): Polling #' + String(iteration_index) + ' _funded_NFTs: ',
          _funded_NFTs
        );
        if (_funded_NFTs <= 0) {
          //if _funded_NFTs has not increased
          return null; //re-do the polling
        }
        //if _funded_NFTs has increased

        console.log('_getFundedNFTs(): returning good');
        return _funded_NFTs; //we're done
      } catch (error) {
        console.log('_getFundedNFTs(): ', error.message);
        throw error;
      }
    },
    9,
    5 * 1000
  );
  console.log('_getFundedNFTs(): polling ended! funded_NFTs_wrapped: ', funded_NFTs_wrapped);
  if (!funded_NFTs_wrapped['is_ok']) {
    //if no result was given from polling
    return {
      funded_NFTs: 0
    };
  }
  //if polling gave some result

  //extract the retrieved list
  const funded_NFTs = funded_NFTs_wrapped['data'];

  return {
    funded_NFTs: funded_NFTs
  };
}
export const getFundedNFTs = async function () {
  return exportedFunctionWrapper(_getFundedNFTs);
};

async function _handleFunding(funding_event_target) {
  //get the number of already funded NFTs
  const response_nfts_getFundedNFTs = await callRestApi('GET', 'nfts/getFundedNFTs');
  const fundedNFTs_data = extractDataFromAxiosResponse(response_nfts_getFundedNFTs);
  if (isUndefinedOrNull(fundedNFTs_data)) {
    throw new Error('_handleFunding(): fundedNFTs_data is undefined or null');
  }
  var funded_NFTs = fundedNFTs_data['funded_NFTs'];
  if (isUndefinedOrNull(funded_NFTs)) {
    throw new Error('_handleFunding(): funded_NFTs is undefined or null');
  }

  if (funded_NFTs > 0) {
    //if the NFT has already been funded
    return {
      //we're done
      funded_NFTs: funded_NFTs
    };
  }
  //if the NFT has not been funded yet

  //generate the funding payment xumm payload
  const fundNFTs_req_data = {
    NFTs_number: 1
  };
  const response_nfts_fundNFTs = await callRestApi('POST', 'nfts/fundNFTs', fundNFTs_req_data);
  const funding_data = extractDataFromAxiosResponse(response_nfts_fundNFTs);
  if (isUndefinedOrNull(funding_data)) {
    throw new Error('_handleFunding(): funding_data is undefined or null');
  }
  console.log('_handleFunding(): funding_data: ', funding_data);

  const payload_created = funding_data['payload_created'];
  if (isUndefinedOrNull(payload_created)) {
    throw new Error('_handleFunding(): payload_created is undefined or null');
  }
  //if we correctly got a xumm payload

  //show the login procedure graphically
  funding_event_target.dispatchEvent('xumm_payload_created', {
    payload_created: payload_created
  }); //async

  //subscribe to that payload
  const refs = payload_created['refs'];
  if (isUndefinedOrNull(refs)) {
    throw new Error('_handleFunding(): refs is undefined or null');
  }
  const websocket_status = refs['websocket_status'];
  if (isUndefinedOrNull(websocket_status)) {
    throw new Error('_handleFunding(): websocket_status is undefined or null');
  }
  const xumm_ws_connection = new WebSocket(websocket_status);
  //throw new Error("_handleFunding(): Made up exception");
  const xumm_payload_ws_result = await handleXUMMPayloadWS(
    xumm_ws_connection,
    funding_event_target
  );

  const has_been_submitted =
    !isUndefinedOrNull(xumm_payload_ws_result['signed']) &&
    xumm_payload_ws_result['signed'] &&
    (isUndefinedOrNull(xumm_payload_ws_result['expired']) || !xumm_payload_ws_result['expired']);

  if (!has_been_submitted) {
    //if the payment has not been submitted
    throw new Error('_handleFunding(): has_been_submitted is false');
  }
  //if the payment has been submitted

  //poll the funded_NFTs from the backend
  return _getFundedNFTs();
}
export const handleFunding = async function (funding_event_target) {
  return exportedFunctionWrapper(_handleFunding, funding_event_target);
};

//-----------------------------------------------------------FAUCET----------------------------------------------------------------
async function _getFaucetState() {
  //poll the funded_NFTs from the backend
  console.log('_getFaucetState(): polling the faucet state...');
  const faucet_state_wrapped = await doCountedPolling(
    async function (iteration_index) {
      //ask funded_NFTs to the backend
      const response_faucet_getFaucetState = await callRestApi('GET', 'faucet/getFaucetState');
      try {
        const _faucet_data = extractDataFromAxiosResponse(response_faucet_getFaucetState);
        const _successfull = _faucet_data['successfull'];
        console.log(
          '_getFaucetState(): Polling #' + String(iteration_index) + ' _successfull: ',
          _successfull
        );
        if (isUndefinedOrNull(_successfull) || !_successfull) {
          //if faucet were not successful
          return null; //re-do the polling
        }
        //if _faucet_data has been found

        console.log('_getFaucetState(): returning good');
        return _faucet_data; //we're done
      } catch (error) {
        console.log('_getFaucetState(): ', error.message);
        throw error;
      }
    },
    9,
    5 * 1000
  );
  console.log('_getFaucetState(): polling ended! faucet_state_wrapped: ', faucet_state_wrapped);
  if (!faucet_state_wrapped['is_ok']) {
    //if no result was given from polling
    return {
      successfull: false,
      datetime: null
    };
  }
  //if polling gave some result

  //extract the retrieved data
  const faucet_state = faucet_state_wrapped['data'];

  return faucet_state;
}
export const getFaucetState = async function () {
  return exportedFunctionWrapper(_getFaucetState);
};

async function _handleELSFaucet(faucet_event_target) {
  //generate the funding payment xumm payload
  const response_faucet_ELSFaucet = await callRestApi('POST', 'faucet/ELSFaucet');
  const faucet_data = extractDataFromAxiosResponse(response_faucet_ELSFaucet);
  if (isUndefinedOrNull(faucet_data)) {
    throw new Error('_handleELSFaucet(): faucet_data is undefined or null');
  }
  console.log('_handleELSFaucet(): faucet_data: ', faucet_data);

  const payload_created = faucet_data['payload_created'];
  if (isUndefinedOrNull(payload_created)) {
    //if no XUMM payload has been returned
    return _getFaucetState(); //we're done
  }
  //if we correctly got a XUMM payload

  //show the login procedure graphically
  faucet_event_target.dispatchEvent('xumm_payload_created', {
    payload_created: payload_created
  }); //async

  //subscribe to that payload
  const refs = payload_created['refs'];
  if (isUndefinedOrNull(refs)) {
    throw new Error('_handleELSFaucet(): refs is undefined or null');
  }
  const websocket_status = refs['websocket_status'];
  if (isUndefinedOrNull(websocket_status)) {
    throw new Error('_handleELSFaucet(): websocket_status is undefined or null');
  }
  const xumm_ws_connection = new WebSocket(websocket_status);
  //throw new Error("_handleELSFaucet(): Made up exception");
  const xumm_payload_ws_result = await handleXUMMPayloadWS(xumm_ws_connection, faucet_event_target);

  const has_been_submitted =
    !isUndefinedOrNull(xumm_payload_ws_result['signed']) &&
    xumm_payload_ws_result['signed'] &&
    (isUndefinedOrNull(xumm_payload_ws_result['expired']) || !xumm_payload_ws_result['expired']);

  if (!has_been_submitted) {
    //if the payment has not been submitted
    throw new Error('_handleELSFaucet(): has_been_submitted is false');
  }
  //if the payment has been submitted

  return _getFaucetState();
}
export const handleELSFaucet = async function (faucet_event_target) {
  return exportedFunctionWrapper(_handleELSFaucet, faucet_event_target);
};

//===========================================================STEP 3================================================================
/*
_content_obj: {
  mode: string -> "cid" or "upload",
  cid?: string,
  file?: File
}
//*/
async function _setNFTContent(_content_obj) {
  const mode = _content_obj['mode'];
  if (isUndefinedOrNull(mode) || (mode !== 'cid' && mode !== 'upload')) {
    throw new Error('setNFTContentMetadata(): mode is wrong');
  }

  if (mode === 'cid') {
    let cid = _content_obj['cid'];
    if (isUndefinedOrNull(cid) || !isString(cid)) {
      throw new Error('setNFTContentMetadata(): cid is wrong');
    }
  } else if (mode === 'upload') {
    let file = _content_obj['file'];
    if (isUndefinedOrNull(file) || !isFile(file)) {
      throw new Error('setNFTContentMetadata(): file is wrong');
    }
  } else {
    throw new Error('setNFTContentMetadata(): unknown mode');
  }

  content_obj = _content_obj;
  console.log('content_obj: ', content_obj);
  return 'OK';
}
export const setNFTContent = async function (_content_obj) {
  return exportedFunctionWrapper(_setNFTContent, _content_obj);
};

/*
_content_metadata_obj: {
  mode: string -> "cid" or "upload",
  cid?: string,
  author?: string,
  name?: string,
  description?: string
}
//*/
async function _setNFTContentMetadata(_content_metadata_obj) {
  const mode = _content_metadata_obj['mode'];
  if (isUndefinedOrNull(mode) || (mode !== 'cid' && mode !== 'upload')) {
    throw new Error('setNFTContentMetadata(): mode is wrong');
  }

  if (mode === 'cid') {
    let cid = _content_metadata_obj['cid'];
    if (isUndefinedOrNull(cid) || !isString(cid)) {
      throw new Error('setNFTContentMetadata(): cid is wrong');
    }
  } else if (mode === 'upload') {
    let author = _content_metadata_obj['author'];
    if (isUndefinedOrNull(author) || !isString(author)) {
      throw new Error('setNFTContentMetadata(): author is wrong');
    }

    let name = _content_metadata_obj['name'];
    if (isUndefinedOrNull(name) || !isString(name)) {
      throw new Error('setNFTContentMetadata(): name is wrong');
    }

    let description = _content_metadata_obj['description'];
    if (isUndefinedOrNull(name) || !isString(name)) {
      throw new Error('setNFTContentMetadata(): description is wrong');
    }
  } else {
    throw new Error('setNFTContentMetadata(): unknown mode');
  }

  content_metadata_obj = _content_metadata_obj;
  console.log('content_metadata_obj: ', content_metadata_obj);
  return 'OK';
}
export const setNFTContentMetadata = async function (_content_metadata_obj) {
  return exportedFunctionWrapper(_setNFTContentMetadata, _content_metadata_obj);
};

/*
_NFT_settings: {
  flags:{
    tfBurnable: bool,
    tfOnlyXRP: bool
    tfTrustLine: bool,
    tfTransferable: bool,
  },
  transfer_fee: string
}
//*/
async function _setNFTSettings(_NFT_settings_obj) {
  const flags = _NFT_settings_obj['flags'];
  if (isUndefinedOrNull(flags)) {
    throw new Error('setNFTSettings(): flags is undefined or null');
  }
  const tfBurnable = flags['tfBurnable'];
  if (isUndefinedOrNull(tfBurnable)) {
    throw new Error('setNFTSettings(): tfBurnable is undefined or null');
  }
  const tfOnlyXRP = flags['tfOnlyXRP'];
  if (isUndefinedOrNull(tfOnlyXRP)) {
    throw new Error('setNFTSettings(): tfOnlyXRP is undefined or null');
  }
  const tfTrustLine = flags['tfTrustLine'];
  if (isUndefinedOrNull(tfTrustLine)) {
    throw new Error('setNFTSettings(): tfTrustLine is undefined or null');
  }
  const tfTransferable = flags['tfTransferable'];
  if (isUndefinedOrNull(tfTransferable)) {
    throw new Error('setNFTSettings(): tfTransferable is undefined or null');
  }
  const transfer_fee = _NFT_settings_obj['transfer_fee'];
  if (isUndefinedOrNull(transfer_fee)) {
    throw new Error('setNFTSettings(): transfer_fee is undefined or null');
  }
  
  NFT_settings_obj = _NFT_settings_obj;
  NFT_settings_obj['taxon'] = 0;
  
  console.log('setNFTSettings(): NFT_settings_obj: ', NFT_settings_obj);
  return 'OK';
}
export const setNFTSettings = async function (_NFT_settings_obj) {
  return exportedFunctionWrapper(_setNFTSettings, _NFT_settings_obj);
};

//===========================================================STEP 4================================================================
async function _getMintedTokens() {
  //poll the minted tokens from the backend
  console.log('_getMintedTokens(): polling the tokens_list...');
  const tokens_list_wrapped = await doCountedPolling(
    async function (iteration_index) {
      //ask minted tokens' info to the backend
      const response_nfts_getTokens = await callRestApi('GET', 'nfts/getTokens');
      const _tokens_data = extractDataFromAxiosResponse(response_nfts_getTokens);
      const _tokens_list = _tokens_data['tokens'];
      console.log(
        '_getMintedTokens(): Polling #' + String(iteration_index) + ' _tokens_list: ',
        _tokens_list
      );
      if (_tokens_list.length <= 0) {
        //if the token list is void
        return null; //re-do the polling
      }
      //if the token list is not void

      return _tokens_list; //we're done
    },
    9,
    5 * 1000
  );
  console.log('_getMintedTokens(): polling ended! tokens_list_wrapped: ', tokens_list_wrapped);
  if (!tokens_list_wrapped['is_ok']) {
    //if no result was given from polling
    return {
      has_been_minted: false,
      user_address: null,
      tokens: null
    };
  }
  //if polling gave some result

  //extract the retrieved list
  const tokens_list = tokens_list_wrapped['data'];

  return {
    has_been_minted: true,
    user_address: user_address,
    tokens: tokens_list
  };
}
export const getMintedTokens = async function () {
  return exportedFunctionWrapper(_getMintedTokens);
};

async function _handleNFTokensMinting(minting_event_target) {
  if (isUndefinedOrNull(content_metadata_obj)) {
    throw new Error('_handleNFTokensMinting(): content_metadata_obj is undefined or null');
  }
  if (isUndefinedOrNull(content_obj)) {
    throw new Error('_handleNFTokensMinting(): content_obj is undefined or null');
  }
  if (isUndefinedOrNull(NFT_settings_obj)) {
    throw new Error('_handleNFTokensMinting(): NFT_settings_obj is undefined or null');
  }
  
  //console.log('_handleNFTokensMinting(): content_obj: ', content_obj);

  //poll the NFToken info from the backend
  console.log('_handleNFTokensMinting(): polling the NFToken_info_obj...');
  const NFToken_info_obj_wrapped = await doCountedPolling(
    async function (iteration_index) {
      var _NFToken_info_obj = null;
      try {
        //ask the NFToken info to the backend
        const response_nfts_getNFTokenInfo = await callRestApi('GET', 'nfts/getNFTokenInfo');
        _NFToken_info_obj = extractDataFromAxiosResponse(response_nfts_getNFTokenInfo);
      } catch (error) {
        throw error;
      }
      console.log(
        '_handleNFTokensMinting(): Polling #' + String(iteration_index) + ' _NFToken_info_obj: ',
        _NFToken_info_obj
      );
      return _NFToken_info_obj;
    },
    10,
    3 * 1000
  );
  console.log(
    '_handleNFTokensMinting(): polling ended! NFToken_info_obj_wrapped: ',
    NFToken_info_obj_wrapped
  );
  if (!NFToken_info_obj_wrapped['is_ok']) {
    //if no result was given from polling
    throw NFToken_info_obj_wrapped['error'];
  }
  //if polling gave some result
  minting_event_target.dispatchEvent('token_info_retrieved'); //async

  //extract the retreived object
  const NFToken_info_obj = NFToken_info_obj_wrapped['data'];

  const issuer = NFToken_info_obj['issuer'];
  if (isUndefinedOrNull(issuer)) {
    throw new Error('_handleNFTokensMinting(): issuer is undefined or null');
  }
  const seqnum = NFToken_info_obj['seqnum'];
  if (isUndefinedOrNull(seqnum)) {
    throw new Error('_handleNFTokensMinting(): seqnum is undefined or null');
  }
  const taxon = NFT_settings_obj['taxon'];
  
  const content_metadata_mode = content_metadata_obj['mode'];
  var content_metadata_cid = null;
  if (content_metadata_mode === 'upload') {
    //if the metadata is not already on IPFS and needs to be uploaded
    const content_mode = content_obj['mode'];
    var content_cid = null;
    if (content_mode === 'upload') {
      //if the content is being uploaded to IPFS
      let content_obj_file = content_obj['file'];

      let ipfs_upload_result = await uploadFile(content_obj_file);
      console.log('_handleNFTokensMinting(): content ipfs_upload_result: ', ipfs_upload_result);

      content_cid = ipfs_upload_result['path'];
      if (isUndefinedOrNull(content_cid)) {
        throw new Error('_handleNFTokensMinting(): content_cid is undefined or null');
      }
    } else if (content_mode === 'cid') {
      //if the content is already on ipfs while metadata is not
      content_cid = content_obj['cid'];
      if (isUndefinedOrNull(content_cid)) {
        throw new Error('_handleNFTokensMinting() #1: content_cid is undefined or null');
      }
    } else {
      //unknown
      throw new Error('_handleNFTokensMinting(): content_mode is in an unknown situation');
    }

    //compose the official metadata file
    const name = content_metadata_obj['name'];
    if (isUndefinedOrNull(name)) {
      throw new Error('_handleNFTokensMinting(): name is undefined or null');
    }
    const description = content_metadata_obj['description'];
    if (isUndefinedOrNull(name)) {
      throw new Error('_handleNFTokensMinting(): description is undefined or null');
    }
    const author = content_metadata_obj['author'];
    if (isUndefinedOrNull(author)) {
      throw new Error('_handleNFTokensMinting(): author is undefined or null');
    }

    var _official_metadata_obj = {
      type: "Aesthetes-XLS20",
      version: "1.0.0",
      name: name,
      description: description,
      author: {
        type: 'default',
        version: "1.0.0",
        name: author
      },
      content: 'cid:' + content_cid,
      issuer: issuer,
      seqnum: seqnum
    };
    if (taxon > 0) {
      _official_metadata_obj['taxon'] = taxon;
    }

    let ipfs_upload_result = await uploadFile(JSONBeautify(_official_metadata_obj), false);
    console.log('_handleNFTokensMinting(): metadata ipfs_upload_result: ', ipfs_upload_result);

    content_metadata_cid = ipfs_upload_result['path'];
    if (isUndefinedOrNull(content_metadata_cid)) {
      throw new Error('_handleNFTokensMinting(): content_metadata_cid is undefined or null');
    }
  } else if (content_metadata_mode === 'cid') {
    //if the content metadata is already on ipfs (so also the content)
    const content_mode = content_obj['mode'];
    if (content_mode !== 'cid') {
      throw new Error(
        '_handleNFTokensMinting(): content_metadata_mode is cid while content_mode is not cid'
      );
    }

    content_metadata_cid = content_metadata_obj['cid'];
    if (isUndefinedOrNull(content_metadata_cid)) {
      throw new Error('_handleNFTokensMinting(): content_metadata_cid is undefined or null');
    }
  } else {
    //unknown
    throw new Error('_handleNFTokensMinting(): content_metadata_mode is in an unknown situation');
  }

  //finally generate the minting xumm payload
  const mintNFTs_req_data = {
    content_metadata_cid: content_metadata_cid,
    NFToken_settings: NFT_settings_obj,
    NFToken_info: NFToken_info_obj
  };
  const response_nfts_mintNFTs = await callRestApi('POST', 'nfts/mintNFTs', mintNFTs_req_data);
  const minting_data = extractDataFromAxiosResponse(response_nfts_mintNFTs);
  if (isUndefinedOrNull(minting_data)) {
    throw new Error('_handleNFTokensMinting(): minting_data is undefined or null');
  }

  const payload_created = minting_data['payload_created'];
  if (isUndefinedOrNull(payload_created)) {
    throw new Error('_handleNFTokensMinting(): payload_created is undefined or null');
  }
  //if we correctly got a xumm payload

  //show the login procedure graphically
  minting_event_target.dispatchEvent('xumm_payload_created', {
    payload_created: payload_created
  }); //async

  //subscribe to that payload
  const refs = payload_created['refs'];
  if (isUndefinedOrNull(refs)) {
    throw new Error('_handleNFTokensMinting(): refs is undefined or null');
  }
  const websocket_status = refs['websocket_status'];
  if (isUndefinedOrNull(websocket_status)) {
    throw new Error('_handleNFTokensMinting(): websocket_status is undefined or null');
  }
  const xumm_ws_connection = new WebSocket(websocket_status);
  //throw new Error("_handleNFTokensMinting(): Made up exception");
  const xumm_payload_ws_result = await handleXUMMPayloadWS(
    xumm_ws_connection,
    minting_event_target
  );

  const has_been_minted =
    !isUndefinedOrNull(xumm_payload_ws_result['signed']) &&
    xumm_payload_ws_result['signed'] &&
    (isUndefinedOrNull(xumm_payload_ws_result['expired']) || !xumm_payload_ws_result['expired']);

  if (!has_been_minted) {
    //if the token has not been minted
    throw new Error('_handleNFTokensMinting(): has_been_minted is false');
  }
  //if the token has been minted

  minting_event_target.dispatchEvent('token_minted'); //async

  //poll the minted tokens from the backend
  return _getMintedTokens();
}
export const handleNFTokensMinting = async function (minting_event_target) {
  return exportedFunctionWrapper(_handleNFTokensMinting, minting_event_target);
};
