import React from 'react';
import { Link } from 'react-router-dom';
import circleColored from '../../assets/images/cerchi-colorati.svg';
import circle1 from '../../assets/images/cerchio1_img-01.svg';
import circle2 from '../../assets/images/cerchio2_img-01.svg';

import { extractXappParameters } from "../../scripts/xumm_handler.mjs";

export const Homepage = () => {
  extractXappParameters();
  return (
    <div className="page-template">
      <div className="lines" />
      <div className="left-col">
        <div className="hero">
          <h1>
            Create your <br />
            own NFT.
          </h1>

          <p>Follow our three simple steps to mint easily your NFT</p>

          <Link to="/create" className="button md dark rounded bold">
            Create your NFT
          </Link>
        </div>
      </div>
      <div className="right-col homepage">
        <div className="image-wrapper homepage">
          <img src={circle1} className="circle-1" />
          <img src={circle2} className="circle-2" />
          <img src={circleColored} />
        </div>
      </div>
    </div>
  );
};
