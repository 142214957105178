import React, { useRef, useState } from 'react';
import { Icon } from '../icons';

type Accordion = {
  children: JSX.Element | JSX.Element[] | string;
  header: string;
};

export const Accordion = (props: Accordion) => {
  const { header, children } = props;

  const container = useRef(null);
  const content = useRef(null);

  const [expanded, setExpanded] = useState<boolean>(false);

  const onToggle = () => {
    // if (!expanded) {
    //   const height = content.current?.offsetHeight;
    //   container.current.style.height = `${height}px`;
    // } else {
    //   container.current.style.height = '0px';
    // }

    setExpanded(!expanded);
  };

  return (
    <div className="accordion">
      <div className="accordion-header" onClick={onToggle}>
        <span>{header}</span>
        <div className={`accordion-icon ${expanded ? 'expanded' : ''}`}>
          <Icon content="arrowDown" />
        </div>
      </div>

      <div className={`accordion-content ${expanded ? 'expanded' : ''}`} ref={container}>
        <div className="content" ref={content}>
          {children}
        </div>
      </div>
    </div>
  );
};
