import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from '../../../../components/shared/checkbox/checkbox';
import { Icon } from '../../../../components/shared/icons';
import { IRootState } from '../../../../reducers';
import { changeStep, setStepData } from '../../../../reducers/form.reducer';
import { generateMintObj } from '../step-3/mint-obj';
import { toast } from 'react-toastify';

export type TStep2Values = {
  name: string | null;
  author: string | null;
  description: string | null;
  content_cid: string | null;
  transfer_fee: number | null;
  flags: {
    tfBurnable: boolean;
    tfOnlyXRP: boolean;
    tfTrustLine: boolean;
    tfTransferable: boolean;
  };
};

const defaultValues: TStep2Values = {
  name: null,
  author: null,
  description: null,
  content_cid: null,
  transfer_fee: 0,
  flags: {
    tfBurnable: false,
    tfOnlyXRP: false,
    tfTrustLine: false,
    tfTransferable: true
  }
};

export const Step2 = () => {
  const dispatch = useDispatch();
  const form = useSelector((state: IRootState) => state.form);

  const [cidMetadata, setCidMetadata] = useState<boolean>(false);
  const [advancedOn, setAdvancedOn] = useState<boolean>(false);

  const toggleAdvanced = () => {
    setAdvancedOn(!advancedOn);
  };

  const {
    register,
    unregister,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isValid }
  } = useForm({ mode: 'onChange', defaultValues });

  const watchtfburnable = watch('flags.tfBurnable');
  const watchtftrustline = watch('flags.tfTrustLine');
  const watchttfonlyxrp = watch('flags.tfOnlyXRP');
  const watchttftransferable = watch('flags.tfTransferable');

  const toggleCidMetadata = (open: boolean) => {
    if (open) {
      register('content_cid', { required: true });
      setValue('content_cid', null);
      setValue('name', null);
      setValue('author', null);
      setValue('description', null);
      unregister('name');
      unregister('author');
      unregister('description');
    } else {
      unregister('content_cid');
      setValue('content_cid', null);
      register('name', { required: true });
      register('author', { required: true });
      register('description', { required: true });
      setValue('name', null);
      setValue('author', null);
      setValue('description', null);
    }

    setCidMetadata(open);
  };

  const handleCheck = (key: 'tfburnable' | 'tftrustline' | 'tfonlyxrp' | 'tftransferable') => {
    if (key === 'tfburnable') {
      setValue('flags.tfBurnable', !watchtfburnable);
    }
    if (key === 'tftrustline') {
      setValue('flags.tfTrustLine', !watchtftrustline);
    }
    if (key === 'tfonlyxrp') {
      setValue('flags.tfOnlyXRP', !watchttfonlyxrp);
    }
    if (key === 'tftransferable') {
      setValue('flags.tfTransferable', !watchttftransferable);
    }
  };

  const onSubmit = (data: any) => {
    dispatch(setStepData('step2', data));
  };

  const onNext = async () => {
    const submitMintData = await generateMintObj(form);

    if (submitMintData) {
      dispatch(changeStep(3));
    } else {
      toast('Unexpected error', { type: 'error' });
    }
  };

  useEffect(() => {
    if (form.step2) {
      onNext();
    }
  }, [form.step2]);

  return (
    <>
      <div className="left-col">
        <div className="hero">
          <h1 className="step-title">Step two</h1>
        </div>
      </div>
      <div className="right-col form">
        <div className="step-form">
          <div className="step-content">
            <div className="form">
              {advancedOn ? (
                <div className="advanced-form">
                  <div className="form-item input">
                    <label htmlFor="transferFee">Transfer fee:</label>
                    <input
                      required
                      className={`form-control ${errors.transfer_fee ? 'is-invalid' : ''}`}
                      id="transferFee"
                      {...register('transfer_fee', { required: true })}
                    />
                    {errors.transfer_fee && (
                      <div className="form-error">This field is required</div>
                    )}
                  </div>

                  <div className="form-item textarea">
                    <label>Flags:</label>
                    <div className="flag-container">
                      <div className="flag-item">
                        <Checkbox
                          checked={watchtfburnable}
                          onCheck={() => handleCheck('tfburnable')}
                        />
                        tF Burnable
                      </div>
                      {/*
                      <div className="flag-item">
                        <Checkbox
                          checked={watchtftrustline}
                          onCheck={() => handleCheck('tftrustline')}
                        />
                        tF Trustline
                      </div>
                      //*/
                      }
                      <div className="flag-item">
                        <Checkbox
                          checked={watchttfonlyxrp}
                          onCheck={() => handleCheck('tfonlyxrp')}
                        />
                        tF Only XRP
                      </div>
                      <div className="flag-item">
                        <Checkbox
                          checked={watchttftransferable}
                          onCheck={() => handleCheck('tftransferable')}
                        />
                        tF Transferable
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <button
                      className="button md dark rounded bold ml-auto"
                      onClick={toggleAdvanced}
                    >
                      Done
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="tab">
                    <div
                      className={`tab-filter ${!cidMetadata ? 'active' : ''} `}
                      onClick={() => toggleCidMetadata(false)}
                    >
                      Insert Metadata
                    </div>
                    <div
                      className={`tab-filter ${cidMetadata ? 'active' : ''} `}
                      onClick={() => toggleCidMetadata(true)}
                    >
                      IPFS CID Metadata
                    </div>
                  </div>

                  {cidMetadata ? (
                    <>
                      <div className="form-item input metadata-cid">
                        <label htmlFor="content_cid">IPFS CID metadata:</label>
                        <input
                          required
                          className={`form-control ${errors.content_cid ? 'is-invalid' : ''}`}
                          id="content_cid"
                          {...register('content_cid', { required: true })}
                        />
                        {errors.content_cid && (
                          <div className="form-error">This field is required</div>
                        )}
                      </div>
                      <div className="hint">
                        This is not the artwork CID, use this field only if you already have a
                        metadata file on IPFS and you know what you are doing.
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="form-item input">
                        <label htmlFor="name">Name</label>
                        <input
                          required
                          className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                          id="name"
                          {...register('name', { required: true })}
                        />
                        {errors.name && <div className="form-error">A name is required</div>}
                      </div>

                      <div className="form-item input">
                        <label htmlFor="author">Author</label>
                        <input
                          required
                          className={`form-control ${errors.author ? 'is-invalid' : ''}`}
                          id="author"
                          {...register('author', { required: true })}
                        />
                        {errors.author && <div className="form-error">An author is required</div>}
                      </div>
                      <div className="form-item textarea">
                        <label htmlFor="description">Description</label>
                        <textarea
                          required
                          className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                          id="description"
                          {...register('description', { required: true })}
                        />
                        {errors.description && (
                          <div className="form-error">A description is required</div>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="step-actions">
            {!advancedOn && (
              <>
                <button className="button md dark rounded bold" onClick={toggleAdvanced}>
                  Advanced options
                </button>
                <button
                  className="button md dark rounded bold "
                  onClick={handleSubmit(onSubmit)}
                  disabled={!isValid}
                >
                  Done
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
