import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavItem } from './nav-item';

export const NavDesktop = () => {
  return (
    <div className="navigation-desktop">
      <div className="nav-primary">
        <NavItem to="/how-it-works" text="How it works" />
        <NavItem to="/create" text="Create your NFT" />
        { /*<NavItem to="/faqs" text="FAQs" /> */}
        <NavItem to="/support" text="Support" />
      </div>
    </div>
  );
};
