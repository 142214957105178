import React, { useEffect, useRef } from 'react';
import { Icon } from '../icons';
import Backdrop from './backdrop';

type ModalProps = {
  close?: () => void;
  showCloseButton?: boolean;
  title?: string;
  children: any;
  size?: TSizes;
  hideHeader?: boolean;
};

type TSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const Modal = (props: ModalProps) => {
  const { close, showCloseButton = true, title, children, size, hideHeader = true } = props;
  const modal = useRef<HTMLDivElement>(null);
  const backdrop = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    modal.current?.classList.remove('open');
    backdrop.current?.classList.remove('open');
    setTimeout(() => {
      close && close();
    }, 100);
  };

  const handleKey = (e: any) => {
    if (e.keyCode === 27) {
      handleClose();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKey);
    document.body.classList.add('oy-hidden');
    return () => {
      window.removeEventListener('keydown', handleKey);
      document.body.classList.remove('oy-hidden');
    };
  }, [handleKey]);

  useEffect(() => {
    setTimeout(() => {
      modal.current?.classList.add('open');
      backdrop.current?.classList.add('open');
    }, 100);
  }, []);

  return (
    <>
      <div ref={backdrop} className="Backdrop" />

      <div ref={modal} className={`Modal ${size || ''}  `}>
        {!hideHeader && (
          <div className="Modal-header">
            {showCloseButton && (
              <button className="button ml-auto" onClick={handleClose}>
                <Icon content="close" />
              </button>
            )}
          </div>
        )}

        <div className="Modal-content">{children}</div>
      </div>
    </>
  );
};
