import { TStep1Values } from '../pages/create/steps/step-1/step-1';
import { TStep2Values } from '../pages/create/steps/step-2/step-2';

export const ACTION_TYPES = {
  SET_STEP: 'form/SET_STEP',
  SET_STEP_DATA: 'form/SET_STEP_DATA',
  RESET: 'form/RESET'
};

const initialState = {
  step: 1,
  step1: null as unknown as TStep1Values,
  step2: null as unknown as TStep2Values
};

export type FormState = Readonly<typeof initialState>;

// Reducer

export default (state: FormState = initialState, action: any): FormState => {
  switch (action.type) {
    case ACTION_TYPES.SET_STEP:
      return {
        ...state,
        step: action.payload
      };

    case ACTION_TYPES.SET_STEP_DATA:
      return {
        ...state,
        [action.payload.step]: action.payload.data
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };

    default:
      return state;
  }
};

export const changeStep = (step: number) => (dispatch: any) => {
  dispatch({
    type: ACTION_TYPES.SET_STEP,
    payload: step
  });
};

export const setStepData = (step: 'step1' | 'step2', data: any) => (dispatch: any) => {
  dispatch({
    type: ACTION_TYPES.SET_STEP_DATA,
    payload: { step, data }
  });
};

export const resetForm = () => (dispatch: any) => {
  dispatch({
    type: ACTION_TYPES.RESET
  });
};
