import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Burger } from './burger';
import { NavDesktop } from './nav-desktop';
import { NavMobile } from './nav-mobile';
import logo from '../../assets/images/logo-minter.png';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../reducers';
import { handleLogout } from '../../scripts/NFT_building_handler.mjs';
import { clearAuthentication } from '../../reducers/auth.reducer';
import { openExternal } from '../../utils';
import { toast } from 'react-toastify';
import { isXumm } from '../../scripts/xumm_handler.mjs';

export const Header = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const dispatch = useDispatch();
  const auth = useSelector((state: IRootState) => state.authentication);

  const onLogout = async () => {
    try {
      const res = await handleLogout();
      if (!res.data?.is_logged_in) {
        dispatch(clearAuthentication());
      } else {
        toast('Unexpected error', { type: 'error' });
      }
    } catch (e: any) {
      toast(e, { type: 'error' });
    }
  };

  return (
    <div className="header">
      <Link to="/" className="logo">
        <img src={logo} alt="Logo" />
      </Link>

      <NavDesktop />

      <div className="nav-secondary">
        {auth.isAuthenticated && !isXumm() && (
          <button className="button sm rounded gradient uppercase bold" onClick={onLogout}>
            log out
          </button>
        )}
        <a
          href="https://www.aesthetes.art"
          onClick={(e) => {
            e.preventDefault();
            openExternal('https://www.aesthetes.art');
          }}
          className="button md dark ml-1 sm-hidden"
        >
          Aesthetes
        </a>
        <Burger state={menuOpen} onClick={toggleMenu} />
      </div>

      {menuOpen && <NavMobile onClose={toggleMenu} />}
    </div>
  );
};
