import React from 'react';
import Accordion from '../../components/shared/accordion';
import SimpleBar from 'simplebar-react';
import scan from '../../assets/images/XLS20-QR.png';

export const Faqs = () => {
  return (
    <div className="faqs-container">
      <SimpleBar style={{ maxHeight: '100%' }}>
        <Accordion header="On which blockchain does the A-Minter run?">
          <>The A-Minter runs on the XRP Ledger.</>
        </Accordion>
        <Accordion header="What is the XLS-20D proposal?">
          <p>
            The XLS-20d proposal introduces extensions to the XRP Ledger that allow to support a
            native NFT type, along with operations to enumerate, transfer and hold such tokens.{' '}
          </p>
          <p>
            With XLS-20d live on XLS-20 Sandbox today, developers can access to all the essential
            NFT functionalities - including minting, trading, and burning.
          </p>
          <p>XLS-20D is still in the testing phase.</p>
        </Accordion>

        <Accordion header="Is the A-Minter working on Mainnet?">
          <>
            As mentioned above: no, it is not. The A-Minter is still in a testing phase and it works
            on the dedicated XLS20D NFT XRPL DevNet.{' '}
          </>
        </Accordion>

        <Accordion header="What is the A-Minter used for?">
          <>
            The A-Minter working on DevNet gives to all the users, and not only to a narrow circle
            of developers, the possibility to test the potentials of the upcoming XLS-20D standard.
          </>
        </Accordion>

        <Accordion header="How can I join the XLS-20D NFT DevNet?">
          <>
            You can join the dedicated XLS-20D NFT DevNet simply by scanning the following QR code
            with your XUMM app.
          </>
          <p>
            <img src={scan} />
          </p>
          <p>
            Then, using your XUMM wallet go to: Settings/Advanced/Node and select:
            xls20-sandbox.ripplenet:51233
          </p>
        </Accordion>

        <Accordion header="How can I create an XRP address on the dedicated XlS20D NFT DevNet?">
          <p>After having select xls20-sandbox.ripplenet:51233,</p>
          <p>
            visit:
            <a href="https://xrpl.org/xrp-testnet-faucet.html">
              https://xrpl.org/xrp-testnet-faucet.html{' '}
            </a>
          </p>
          <p>and select “Generate NFT-DEVNET credentials”.</p>
          <p>
            Then, return to your XUMM wallet, click “+Add account” and import the dedicated secret
            phase generated in the previous step.
          </p>
        </Accordion>

        <Accordion header="Is it possible to upload and mint my artwork?">
          <>
            No, it is not. It is a TESTING tool so, you should upload artwork tests only. Be
            careful!
          </>
        </Accordion>
      </SimpleBar>
    </div>
  );
};
