import { isUndefinedOrNull } from './utils.mjs';

import {
  callRestApi,
  extractDataFromAxiosResponse
} from './network_utils.mjs';

/*
var endpoints_URL = null;
export const setEndpointsURL = function (_endpoints_URL) {
  endpoints_URL = _endpoints_URL;
  console.log("ipfs_utils.mjs: setEndpointsURL(): ", endpoints_URL);
}
//*/

export const uploadFile = async function(_file, is_file = true){
  console.log("uploadFile(): _file: ", _file);
  console.log("uploadFile(): is_file: ", is_file);
  
  var result = null;
  try{
    var uploadToIPFS_res_obj = null;
    if(is_file){ //if we are uploading a file
      //console.log("uploadFile(): uploading a file...");
      
      var form_data = new FormData();
      form_data.append('file', _file, 'file');
      form_data.append('json', JSON.stringify({
        "is_file": is_file,
        //"endpoints_url": endpoints_URL
      }));
      
      uploadToIPFS_res_obj = await callRestApi(
        "POST",
        "upload/uploadToIPFS",
        form_data,
        {
          'maxContentLength': Infinity,
          'maxBodyLength': Infinity
        }
      );
    }
    else{ //if we are not uploading a file
      //console.log("uploadFile(): uploading a non file...");
      
      const uploadToIPFS_req_obj = {
        "is_file": is_file,
        "to_upload": _file,
        //"endpoints_url": endpoints_URL
      };
      
      uploadToIPFS_res_obj = await callRestApi(
        "POST",
        "upload/uploadToIPFS",
        uploadToIPFS_req_obj
      );
    }
    console.log("uploadFile(): uploadToIPFS_res_obj: ", uploadToIPFS_res_obj);
    
    const res_data = extractDataFromAxiosResponse(uploadToIPFS_res_obj);
    console.log("uploadFile(): res_data: ", res_data);
    
    result = res_data["upload_result"];
  }
  catch(ipfs_api_error){ //if there was some error when contacting the api
    console.log("uploadFile(): ", ipfs_api_error);
  }
  
  if(isUndefinedOrNull(result)){ //if no file has been uploaded
    throw new Error("Unable to upload to IPFS");
  }
  
  return result;
}
